@import "../../_variables";

.legal-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-out;
  overflow: auto;
  padding: 20px 0;
}

.legal-modal {
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  background: #111;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 200, 7, 0.2);
  animation: modalSlideIn 0.4s ease-out;
  position: relative;
  margin: auto;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: radial-gradient(
      circle at center,
      rgba(255, 200, 7, 0.15) 0%,
      rgba(0, 0, 0, 0) 80%
    );
    z-index: 0;
    pointer-events: none;
  }
}

.legal-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #ffc807;
    letter-spacing: -0.5px;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 2rem;
    line-height: 1;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transition: all 0.2s;
    padding: 0 0.5rem;

    &:hover {
      color: #ffc807;
      transform: scale(1.1);
    }
  }
}

.legal-modal-content {
  padding: 1.5rem;
  overflow-y: auto;
  max-height: calc(90vh - 6rem);
  flex: 1;
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 1;

  h3 {
    color: #ffc807;
    margin: 1.5rem 0 1rem;
    font-size: 1.2rem;
    font-weight: 600;

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  a {
    color: #ffc807;
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol {
    margin-bottom: 1rem;
    padding-left: 1.5rem;

    li {
      margin-bottom: 0.5rem;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .legal-modal-header {
    padding: 1rem;

    h2 {
      font-size: 1.3rem;
    }
  }

  .legal-modal-content {
    padding: 1rem;
    max-height: calc(90vh - 5rem);

    h3 {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 480px) {
  .legal-modal {
    width: 95%;
    max-height: 95vh;
  }

  .legal-modal-header {
    padding: 0.8rem;

    h2 {
      font-size: 1.1rem;
    }

    .close-button {
      font-size: 1.5rem;
    }
  }

  .legal-modal-content {
    padding: 0.8rem;
    font-size: 0.9rem;
    max-height: calc(95vh - 4rem);

    h3 {
      font-size: 1rem;
    }
  }
}
