@import "../../../_variables";

.contact-section {
  padding: 6rem 1.5rem;
  position: relative;
  background-color: #0a0a0a;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 200, 7, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 1;
  }

  // Starry background effect
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: radial-gradient(
      circle at center,
      rgba(255, 200, 7, 0.02) 1px,
      transparent 1px
    );
    background-size: 30px 30px;
    z-index: 0;
    opacity: 0.5;
    pointer-events: none;
  }
}

.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  position: relative;
  z-index: 2;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}

.contact-text {
  h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #ffc807;
    font-weight: 700;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 40px;
      height: 3px;
      background-color: #ffc807;
    }
  }

  p {
    font-size: 1.1rem;
    line-height: 1.7;
    color: rgba(255, 255, 255, 0.8);
    max-width: 500px;
  }
}

.contact-form {
  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-input,
  .form-textarea {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    padding: 1rem 1.25rem;
    color: white;
    font-size: 1rem;
    transition: all 0.3s ease;
    outline: none;

    &:hover {
      border-color: rgba(255, 200, 7, 0.3);
    }

    &:focus {
      border-color: rgba(255, 200, 7, 0.5);
      background-color: rgba(255, 255, 255, 0.07);
      box-shadow: 0 0 0 2px rgba(255, 200, 7, 0.1);
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .form-textarea {
    min-height: 120px;
    resize: vertical;
  }

  .submit-button {
    background-color: rgba(255, 200, 7, 0.9);
    border: none;
    border-radius: 4px;
    color: #111;
    font-weight: 600;
    padding: 1rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 0.5rem;

    &:hover {
      background-color: #ffc807;
      transform: translateY(-2px);
      box-shadow: 0 4px 15px rgba(255, 200, 7, 0.2);
    }

    &:active {
      transform: translateY(0);
    }

    &.submitting {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .form-success,
  .form-error {
    padding: 1rem;
    margin-top: 1.5rem;
    border-radius: 4px;
    font-size: 0.95rem;
    animation: fadeIn 0.5s ease-out;
  }

  .form-success {
    background-color: rgba(37, 211, 102, 0.1);
    border: 1px solid rgba(37, 211, 102, 0.3);
    color: rgb(37, 211, 102);
  }

  .form-error {
    background-color: rgba(231, 76, 60, 0.1);
    border: 1px solid rgba(231, 76, 60, 0.3);
    color: rgb(231, 76, 60);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .contact-section {
    padding: 4rem 1.25rem;
  }

  .contact-text {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
}

@media (max-width: 480px) {
  .contact-section {
    padding: 3rem 1rem;
  }

  .contact-container {
    gap: 2rem;
  }

  .contact-text {
    h2 {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.95rem;
      line-height: 1.6;
    }
  }

  .contact-form {
    .form-input,
    .form-textarea {
      padding: 0.85rem 1rem;
      font-size: 0.95rem;
    }

    .submit-button {
      width: 100%;
      padding: 0.85rem 1rem;
    }
  }
}
